<template>
  <div class="board-wrap">
    <div v-for="(item, index) in goodsList" :key="index" class="item">
      <div class="item-top">
        <div class="info">
          <div class="info-left flex-c">
            <div class="status border-red">{{ $CONSTANT.goodsTypeMap.O[item.type]||'-' }}</div>
            <div class="car-order-num" @click="toDetail(item)">{{ item.goodsSerial || "--" }}</div>
            <div class="locus flex-c" @click="openMap(item)">
              <el-tooltip content="轨迹" placement="top">
                <img class="cursor" src="@/assets/images/common/tansport.svg">
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="item-bottom">
        <div class="left">
          <div>
            <img src="@/assets/images/goodsOrder/qiyeguanli1.png">
            <!-- size-type="table" -->
            <UserInfoCard :user-info="item" />
          </div>
          <div class="huo">
            <img src="@/assets/images/goodsOrder/huo1.png">
            <span>{{ item.goodsName || '--' }} {{ $parseNumber(item.total,1000) }}吨 {{ item.truckNum || "--" }}车</span>
          </div>
          <div class="line">
            <img src="@/assets/images/goodsOrder/line1.png">
            <span><TextToolTip :text="`${item.loadAddrName||'-'}-${item.unloadAddrName||'-'}`" /></span>
          </div>
          <div class="time">
            <img src="@/assets/images/goodsOrder/yu1.png">
            <span>装 <span>{{ item.loadEarlyTime | parseTime('MM-DD HH:mm') }}</span></span>
            <span>卸 <span>{{ item.unloadEarlyTime | parseTime('MM-DD HH:mm') }}</span></span>
          </div>
        </div>
        <div class="mid">
          <div class="timeline-wrap">
            <Timeline :need-axios="false" :order-id="Number(item.id)" :type="+item.type===1?1.1:1.2" :external-data="item" />
          </div>
        </div>
        <div class="right">
          <div class="main-c cursor" @click="toDetail(item)">查看</div>
        </div>
      </div>
    </div>
    <div v-if="!goodsList.length" class="no-data">
      暂无数据
    </div>
  </div>
</template>

<script>
// import Timeline from './timeline';
import Timeline from '@/components/OrderBusiness/timeline';
import { parseTime } from '@/utils';
export default {
  components: { Timeline },
  filters: {
    parseTime,
  },
  props: {
    goodsList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    toDetail({ id }) {
      this.$router.push(`/transport/goods/detail?id=${id}`);
    },
    // 跳转运力监控
    openMap(data) {
      if (data.id) {
        this.$router.push(`/transport/monitor?id=${data.id}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

